
jQuery.noConflict();

jQuery(window).scroll(function(){
    if (jQuery(window).scrollTop() > 0) {
        jQuery('header').addClass('sticky');
        jQuery('.fixSticky').css('height', jQuery('header').height()+'px');
    }
    else {
        jQuery('header').removeClass('sticky');
        jQuery('.fixSticky').css('height', '0px');
    }
});

function loadMaps(divName, lat, lng){

    var point = new google.maps.LatLng(lat, lng);

    var myMapOptions = {
        mapTypeControlOptions: {
        },
        zoom: 14,
        center: point
    };

    var map = new google.maps.Map(document.getElementById(divName), myMapOptions);

    var image = new google.maps.MarkerImage(
        '/app/themes/physio2go/assets/images/marker.png',
        new google.maps.Size(35,49),
        new google.maps.Point(0,0)
    );

    var marker = new google.maps.Marker({
        draggable: false,
        raiseOnDrag: false,
        icon: image,
        map: map,
        position: point
    });

    var infowindow = new google.maps.InfoWindow();
}

jQuery(document).ready(function() {

    var $menu = jQuery('#mainNav'),
        $menulink = jQuery('.mobileMenu');

    $menulink.click(function(e) {
        e.preventDefault();
        $menulink.toggleClass('active');
        $menu.toggleClass('active');
    });

    jQuery('#map').each(function(){
        var lat = jQuery(this).data('lat');
        var lng = jQuery(this).data('long');

        if (lat && lng){
            loadMaps(jQuery(this).attr('id'), lat, lng);
        }
    });
});